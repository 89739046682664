import { lazy } from 'react';
import Loadable from './Loadable';


// Landing Page
const LandingPage = Loadable(lazy(() => import('../pages/landing-page/LandingPage')));


export const routes = () => {
  return [
  // Landing Page
  {  path: '/', element: <LandingPage /> },
    ];
};