import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import App from './App';
import PublicApp from "./publicApp"
import theme from './theme';

import Loader  from "./globalLoader";
import { getData, getSession , setSession  } from './api-handlers/apiFunctions';
import { useState , useEffect } from 'react';


const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement);

// const dev_password = localStorage.getItem("internal_team_password")


const RootComponent = () => {
  const [isVerified, setIsVerified] = useState(null);

  useEffect(() => {
    const verifyDevToken = async () => {
      const devToken = getSession( process.env.REACT_APP_DEV_TOKEN_NAME );
      if (devToken) {
        const headers =  { 'X-Custom-Token': devToken }
        try {
          const result = await getData( process.env.REACT_APP_DEV_VERIFY_ENDPOINT , headers );
          if (result.success){
            setSession( process.env.REACT_APP_DEV_TOKEN_NAME , result.data.token)
            setIsVerified(true);
          }  
        }
        catch(error){
          console.log(error)          
        }
      } 
      else { setIsVerified(false)}
    };


    if( process.env.REACT_APP_MODE === "prod" ){ setIsVerified(true) }
    else{ verifyDevToken() }

  }, []);

  if (isVerified === null) {
    return <Loader />; // You can show a loader while verifying the token
  }

  return (
    
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {isVerified ? <App /> : <PublicApp />}
    </ThemeProvider>
  );
};

root.render(<RootComponent />);





// if ( dev_password ) {
//   root.render(
//     <ThemeProvider theme={theme}>
//       {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
//       <CssBaseline />
//       <App />
//     </ThemeProvider>,
//   );
// }
// else{
//   root.render(
//     <ThemeProvider theme={theme}>
//       {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
//       <CssBaseline />
//       <PublicApp />
//     </ThemeProvider>,
//   );  
// }

