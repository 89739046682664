// Loader.js
import React from 'react';
import { RingLoader } from 'react-spinners';

const Loader = () => {
  return (
    <div style={loaderStyle}>
      <RingLoader color="#3498db" size={150} />
    </div>
  );
};

const loaderStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh', // Full viewport height
};

export default Loader;
